import { Injectable } from "@angular/core";
import { environment } from "../../environments/environment";

@Injectable()
export class Configuration {

}
function createUrl(actionName: string) {
  return `${environment.apiHost}/${actionName}`;
}

export const appApiResources = {
  // baseUrl: environment.apiHost,



  //user & login
  userLogin: createUrl('auth/user/login/'),
  createUser: createUrl('auth/create/user/'),
  getAllUsers: createUrl('auth/get/all/user'),
  getAllResearcher: createUrl('auth/project/leads/'),
  userUpdate: createUrl('auth/user/profile/update/'),
  forgotPassword: createUrl('auth/user/password/reset/request/'),
  //inActiveUser: createUrl('auth/user/status/update/'),
  activeUser: createUrl('auth/user/status/update/'),
  userFilter: createUrl('auth/project/role/filter/'),
  resetPassword: createUrl('auth/user/password/reset/complete/'),


   


  //project
  createNewProject: createUrl('product/project/create/'),
  updateProject: createUrl('product/project/update/'),
  projectList: createUrl('product/all/projects/'),
  deleteProject: createUrl('product/delete/project/'),
  projectStatusChange: createUrl('product/project/status/update/'),
  getUserList: createUrl('product/responded/get_by_project'),


  //ingredients
  getAllFoodGroups: createUrl('product/all/food_groups/'),
  getAllIngredients: createUrl('product/all/ingredients/'),
  createIngredients: createUrl('product/ingredients/create/'),
  updataIngredients: createUrl('product/ingredients/update/'),
  deleteIngredients: createUrl('product/delete/ingredient/'),
  ingredientsStatusChange: createUrl('product/update/ingredient/status/'),


  //recepies
  getAllRecipes: createUrl('product/all/recipes/'),
  createRecipes: createUrl('product/recipe/create/'),
  updateRecipes: createUrl('product/recipe/update/'),
  deleteRecipes: createUrl('product/delete/recipe/'),
  recipeStatusChange: createUrl('product/update/recipe/status/'),
  recipeNutrientFact: createUrl('product/ingredients/base/nutrients/calc/'),
  exportRecipe: createUrl('product/export/recipe/csv/'),

 
  //Responded & Diet
  getAllResponded: createUrl('product/all/responded/'),
  createResponded: createUrl('product/responded/create/'),
  updateResponded: createUrl('product/update/responded/'),
  dietCalculation: createUrl('product/recipe/nutrients/calculation/'),
  getRespondedDiet: createUrl('product/get/responded/diet/'),
  exportNutrients: createUrl('product/generate/nutrients/facts/'),
  exportfoodfrequency: createUrl('product/generate/food_frequency/'),
  createDiet: createUrl('product/create/diet/'),
  dietStatusChange: createUrl('product/diet/status/update/'),
  updateDiet: createUrl('product/update/diet/'),
  exportProjectNutrients: createUrl('product/project/generate/nutrients/facts/'),
  exportProjectFoodfrequency: createUrl('product/project/generate/food_frequency/'),
  

  
  
  //FieldWorker
  getFieldWorker: createUrl('product/get/fieldwork/responded/'),
  getstatistics: createUrl('auth/get/all/statistic/'),

};
